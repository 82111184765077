/**
 * @generated SignedSource<<3c7986becdd4d90e9545c3a870e204ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontHead_seller$data = {
  readonly favoritesByUser?: ReadonlyArray<{
    readonly serviceId: string | null;
  } | null> | null;
  readonly sellerProfile: {
    readonly company: string | null;
  } | null;
  readonly serviceId: string | null;
  readonly storefrontProfile: {
    readonly aboutUs: string | null;
    readonly photos: ReadonlyArray<{
      readonly path: string | null;
      readonly type: {
        readonly name: string | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontDetails_seller" | "StorefrontFollowButton_seller" | "StorefrontSocial_seller">;
  readonly " $fragmentType": "StorefrontHead_seller";
};
export type StorefrontHead_seller$key = {
  readonly " $data"?: StorefrontHead_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontHead_seller">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "hasUserId"
    },
    {
      "kind": "RootArgument",
      "name": "storefrontId"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontHead_seller",
  "selections": [
    (v0/*: any*/),
    {
      "condition": "hasUserId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "userIds",
              "variableName": "userId"
            }
          ],
          "concreteType": "Favorite",
          "kind": "LinkedField",
          "name": "favoritesByUser",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ]
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SellerProfile",
      "kind": "LinkedField",
      "name": "sellerProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "company",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "storefrontId",
          "variableName": "storefrontId"
        }
      ],
      "concreteType": "StorefrontProfile",
      "kind": "LinkedField",
      "name": "storefrontProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "aboutUs",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Photo",
          "kind": "LinkedField",
          "name": "photos",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "path",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PhotoType",
              "kind": "LinkedField",
              "name": "type",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontFollowButton_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontSocial_seller"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontDetails_seller"
    }
  ],
  "type": "Seller",
  "abstractKey": null
};
})();

(node as any).hash = "8410b4c5969fc0c58bc5ae655cf0bf67";

export default node;
