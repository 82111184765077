import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type DiamondFilledProps = {
    className?: string;
};

const DiamondFilled: FunctionComponent<DiamondFilledProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'diamond-filled'}
            viewBox="0 0 250 250"
        >
            <polygon points="159.5,79.4 123.3,26.2 89.2,79.4" />
            <polygon points="114.2,22.3 58,22.3 81,74.1" />
            <polygon points="137.1,221.9 243.6,89.1 172.3,89.1" />
            <polygon points="243.7,79.5 202.1,26.5 176.3,79.5" />
            <polygon points="193.4,22.3 132.2,22.3 167.9,74.7" />
            <polygon points="49.4,26.7 6.8,79.5 72.9,79.5" />
            <polygon points="86.6,89.1 125.3,228.5 162.3,89.1" />
            <polygon points="5.7,89.2 114.5,222 75.4,89.2" />
            <polygon points="89.1,79.5 89.2,79.4 89.1,79.4" />
        </svg>
    );
};
export default DiamondFilled;
