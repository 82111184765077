/**
 * @generated SignedSource<<bcc7aa150ca1babdacad9896c33b29b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontFeaturedSection_products$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontProductGrid_products">;
  readonly " $fragmentType": "StorefrontFeaturedSection_products";
};
export type StorefrontFeaturedSection_products$key = {
  readonly " $data"?: StorefrontFeaturedSection_products$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontFeaturedSection_products">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontFeaturedSection_products",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontProductGrid_products"
    }
  ],
  "type": "DealerStorefrontQueryConnection",
  "abstractKey": null
};

(node as any).hash = "2d2c8610c5ed41f25743180021d9d7ec";

export default node;
