/**
 * @generated SignedSource<<74749431388f34d0ea4b0de7fe4f78ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontDetails_seller$data = {
  readonly isDistinguished: boolean | null;
  readonly isRewardsTier2: boolean | null;
  readonly orderFeedbackPdp: {
    readonly metadata: {
      readonly aggregatedFeedback: {
        readonly overallExperience: {
          readonly mean: number | null;
        } | null;
      } | null;
    } | null;
  } | null;
  readonly sellerProfile: {
    readonly company: string | null;
  } | null;
  readonly sellerTiers: {
    readonly isGoldTier: boolean | null;
    readonly isPlatinumTier: boolean | null;
  } | null;
  readonly serviceId: string | null;
  readonly shippingAddress: {
    readonly displayCityStateCountry: string | null;
  } | null;
  readonly " $fragmentType": "StorefrontDetails_seller";
};
export type StorefrontDetails_seller$key = {
  readonly " $data"?: StorefrontDetails_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontDetails_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontDetails_seller",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDistinguished",
      "storageKey": null
    },
    {
      "alias": "isRewardsTier2",
      "args": [
        {
          "kind": "Literal",
          "name": "listName",
          "value": "SELLER_REWARDS_2"
        }
      ],
      "kind": "ScalarField",
      "name": "isAnchorListMember",
      "storageKey": "isAnchorListMember(listName:\"SELLER_REWARDS_2\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SellerProfile",
      "kind": "LinkedField",
      "name": "sellerProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "company",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "shippingAddress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayCityStateCountry",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SellerOrderFeedbackPdpQueryConnection",
      "kind": "LinkedField",
      "name": "orderFeedbackPdp",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SellerOrderFeedbackPdpQueryMetadata",
          "kind": "LinkedField",
          "name": "metadata",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AggregatedOrderFeedbackType",
              "kind": "LinkedField",
              "name": "aggregatedFeedback",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AggregatedOrderFeedbackQuestionType",
                  "kind": "LinkedField",
                  "name": "overallExperience",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "mean",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SellerTiers",
      "kind": "LinkedField",
      "name": "sellerTiers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isGoldTier",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isPlatinumTier",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "d73fcb7d6637ee44bba7bec71b5c6a50";

export default node;
