import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type LocationPinProps = {
    className?: string;
};

const LocationPin: FunctionComponent<LocationPinProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'location-pin'}
            viewBox="0 0 250 250"
        >
            <path
                fill="none"
                d="M124.1 10.8C103.5 10.8 82.7 21 67 38.6c-18.1 20.4-26.7 48-23.4 75.5 6.2 52.4 66 108.3 80.5 121.1 15.4-13.4 77-69.9 80.5-120.9 2-29.6-7.8-58.2-26.8-78.5-15.2-16.1-34.2-25-53.7-25zm-.3 130.7c-22.2 0-40.3-18.1-40.3-40.3s18.1-40.3 40.3-40.3 40.3 18.1 40.3 40.3-18 40.3-40.3 40.3z"
            />
            <path
                fill="none"
                d="M123.8 71.8c-16.2 0-29.3 13.2-29.3 29.3s13.2 29.3 29.3 29.3 29.3-13.2 29.3-29.3-13.1-29.3-29.3-29.3z"
            />
            <path d="M185.8 28.4C168.6 10 146.6-.2 124.1-.2c-23.8 0-47.6 11.5-65.3 31.5C38.5 54.1 29 84.7 32.7 115.4c3.2 26.8 18.9 57.1 46.8 90 10.8 12.8 21.3 23.3 28.2 29.8C121.1 248 122 248 124.1 248c1.3 0 2.5-.4 3.5-1.2 3.4-2.8 83.8-68.8 88-131.6 2.1-32.6-8.7-64.3-29.8-86.8zm18.7 86c-3.4 51-65 107.5-80.5 120.9-14.5-12.9-74.3-68.7-80.5-121.1C40.3 86.6 48.8 59 67 38.6c15.7-17.6 36.5-27.8 57.1-27.8 19.5 0 38.5 8.9 53.7 25.1 19 20.3 28.7 49 26.7 78.5z" />
            <path d="M123.8 60.8c-22.2 0-40.3 18.1-40.3 40.3s18.1 40.3 40.3 40.3 40.3-18.1 40.3-40.3-18-40.3-40.3-40.3zm0 69.7c-16.2 0-29.3-13.2-29.3-29.3s13.2-29.3 29.3-29.3 29.3 13.2 29.3 29.3-13.1 29.3-29.3 29.3z" />
        </svg>
    );
};
export default LocationPin;
