import PropTypes from 'prop-types';

import classNames from 'classnames';

import PrevArrow from 'dibs-icons/exports/legacy/ArrowLeft';
import NextArrow from 'dibs-icons/exports/legacy/ArrowRight';

import styles from './styles.scss';

function CarouselWrapper({ disable, onNext, onPrevious, children }) {
    const className = classNames(styles.mediaCarousel, {
        [styles.disable]: disable,
    });

    return (
        <div className={className} data-tn="carousel-wrapper">
            {/* Carousel */}
            {children}

            {/* Arrows */}
            <div className={styles.prev} onClick={onPrevious} data-tn="carousel-wrapper-prev">
                <PrevArrow className={styles.arrow} />
            </div>
            <div className={styles.next} onClick={onNext} data-tn="carousel-wrapper-next">
                <NextArrow className={styles.arrow} />
            </div>
        </div>
    );
}

CarouselWrapper.propTypes = {
    disable: PropTypes.bool,
    children: PropTypes.object.isRequired,

    onNext: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,
};

export default CarouselWrapper;
