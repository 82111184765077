import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type IphoneProps = {
    className?: string;
    noFill?: boolean;
};

const Iphone: FunctionComponent<IphoneProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'iphone'}
            viewBox="0 0 250 250"
        >
            <path
                fill={props.noFill ? '' : '#FFFFFF'}
                d="M181.3,244H66.9c-1.5,0-2.7-1.2-2.7-2.7V8.8c0-1.5,1.2-2.7,2.7-2.7h114.6c1.5,0,2.7,1.2,2.7,2.7v232.5
	C184,242.7,182.8,244,181.3,244z"
            />
            <path
                d="M182.3,0H67.7C62.9,0,59,3.9,59,8.8v232.5c0,4.8,3.9,8.8,8.8,8.8h114.4c4.8,0,8.8-3.9,8.9-8.8V8.8C191,3.9,187.1,0,182.3,0z
	 M178.9,237.9H71.1V12.1h27.3l2.5,14.5c0,0.3,0.1,0.5,0.4,0.5h47.3c0.1,0,0.4-0.3,0.4-0.5l2.5-14.5h27.6V237.9z"
            />
        </svg>
    );
};
export default Iphone;
