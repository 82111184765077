import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import StorefrontAboutSection from './StorefrontAboutSection';
import StorefrontMediaSection from './StorefrontMediaSection';
import StorefrontFeaturedSection from './StorefrontFeaturedSection';
import StorefrontCreatorsUrls from './StorefrontCreatorsUrls';

import { useClientState } from 'dibs-react-hooks/exports/useClientState';

function StorefrontHome({ seller, storefrontData, sellerData, isPreview, isTrade, user, viewer }) {
    const isClient = useClientState();
    return (
        <>
            {/* About {Seller} */}
            <StorefrontAboutSection seller={seller} sellerData={sellerData} />

            {/* Featured Pieces */}
            {storefrontData?.edges?.length ? (
                <StorefrontFeaturedSection
                    isPreview={isPreview}
                    seller={seller}
                    isTrade={isTrade}
                    user={user}
                    viewer={viewer}
                    products={storefrontData}
                />
            ) : null}

            {/* Media Section */}
            <StorefrontMediaSection seller={seller} />

            {/* Creators URLs */}
            {isClient && <StorefrontCreatorsUrls featuredCreatorsUrls={storefrontData} />}
        </>
    );
}

StorefrontHome.propTypes = {
    isTrade: PropTypes.bool,
    isPreview: PropTypes.bool.isRequired,
    sellerData: PropTypes.shape({
        dealerSince: PropTypes.string.isRequired,
    }).isRequired,

    // fetched data
    user: PropTypes.object,
    viewer: PropTypes.object.isRequired,
    seller: PropTypes.object.isRequired,
    products: PropTypes.object,
    creatorsUrls: PropTypes.object,
    storefrontData: PropTypes.object,
};

export default createFragmentContainer(StorefrontHome, {
    viewer: graphql`
        fragment StorefrontHome_viewer on Viewer {
            ...StorefrontFeaturedSection_viewer
        }
    `,
    user: graphql`
        fragment StorefrontHome_user on User {
            ...StorefrontFeaturedSection_user
        }
    `,
    seller: graphql`
        fragment StorefrontHome_seller on Seller {
            ...StorefrontAboutSection_seller
            ...StorefrontMediaSection_seller
            ...StorefrontFeaturedSection_seller
        }
    `,
    storefrontData: graphql`
        fragment StorefrontHome_storefrontData on DealerStorefrontQueryConnection {
            edges {
                __typename
            }
            ...StorefrontFeaturedSection_products
            ...StorefrontCreatorsUrls_featuredCreatorsUrls
        }
    `,
});
