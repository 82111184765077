/**
 * @generated SignedSource<<62d8bec86dbbb813dc5b3b68e7f3d80e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontFeaturedSection_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontProductGrid_viewer">;
  readonly " $fragmentType": "StorefrontFeaturedSection_viewer";
};
export type StorefrontFeaturedSection_viewer$key = {
  readonly " $data"?: StorefrontFeaturedSection_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontFeaturedSection_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontFeaturedSection_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontProductGrid_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "d7f364a176f42757299c334987f84e09";

export default node;
