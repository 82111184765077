/**
 * @generated SignedSource<<2af2e18e8d63560e296014b576de6e8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontHome_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontFeaturedSection_viewer">;
  readonly " $fragmentType": "StorefrontHome_viewer";
};
export type StorefrontHome_viewer$key = {
  readonly " $data"?: StorefrontHome_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontHome_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontHome_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontFeaturedSection_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "e604d626df963f5912fa6bbad941e3b7";

export default node;
