/**
 * @generated SignedSource<<f1b076fbba34d37790f8eb44cdb80e06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CallDealerModal_seller$data = {
  readonly serviceId: string | null;
  readonly shippingAddress: {
    readonly displayCityStateCountry: string | null;
  } | null;
  readonly " $fragmentType": "CallDealerModal_seller";
};
export type CallDealerModal_seller$key = {
  readonly " $data"?: CallDealerModal_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"CallDealerModal_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CallDealerModal_seller",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "shippingAddress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayCityStateCountry",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "b7f63f5401a7c6cb76d0fef7aa2e4324";

export default node;
