import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type TradeArrowProps = {
    className?: string;
};

const TradeArrow: FunctionComponent<TradeArrowProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'trade-arrow'}
            viewBox="0 0 250 250"
        >
            <polygon points="250,125 159.1,215.9 152.8,209.5 232.8,129.5 0,129.5 0,120.5 232.8,120.5 152.8,40.5 159.1,34.1" />
        </svg>
    );
};
export default TradeArrow;
