/**
 * @generated SignedSource<<e1bc6181f6653a8ae8992e5551bf2880>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontMediaSection_seller$data = {
  readonly sellerProfile: {
    readonly company: string | null;
  } | null;
  readonly storefrontProfile: {
    readonly galleryAlbum: {
      readonly mediaList: ReadonlyArray<{
        readonly element: {
          readonly caption?: string | null;
          readonly mediaType?: string | null;
          readonly path?: string | null;
          readonly serviceId?: string | null;
          readonly url?: string | null;
        } | null;
        readonly sortOrder: string | null;
      } | null> | null;
    } | null;
  } | null;
  readonly " $fragmentType": "StorefrontMediaSection_seller";
};
export type StorefrontMediaSection_seller$key = {
  readonly " $data"?: StorefrontMediaSection_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontMediaSection_seller">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "caption",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaType",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "storefrontId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontMediaSection_seller",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SellerProfile",
      "kind": "LinkedField",
      "name": "sellerProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "company",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "storefrontId",
          "variableName": "storefrontId"
        }
      ],
      "concreteType": "StorefrontProfile",
      "kind": "LinkedField",
      "name": "storefrontProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MediaAlbum",
          "kind": "LinkedField",
          "name": "galleryAlbum",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MediaElement",
              "kind": "LinkedField",
              "name": "mediaList",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sortOrder",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "element",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "path",
                          "storageKey": null
                        },
                        (v1/*: any*/),
                        (v2/*: any*/)
                      ],
                      "type": "Photo",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "url",
                          "storageKey": null
                        },
                        (v1/*: any*/),
                        (v2/*: any*/)
                      ],
                      "type": "Video",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Seller",
  "abstractKey": null
};
})();

(node as any).hash = "10f98e3a8538063690d15c755029b36a";

export default node;
