import { Component } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { FormattedMessage } from 'dibs-react-intl';
import { findDOMNode } from 'react-dom';

import classNames from 'classnames';

import { Link } from 'dibs-elements/exports/Link';

import { shouldRenderContactModal } from '../helpers/addressPreferences';
import { ABOUT_CHAR_LIMIT, ABOUT_CHAR_LIMIT_MOBILE } from '../helpers/constants';

import StorefrontSectionHeader from './StorefrontSectionHeader';
import StorefrontContactModal from '../contactModal/StorefrontContactModal';

import styles from './styles/StorefrontAboutSection.scss';

function addEllipsis(text, charLimit) {
    if (typeof text !== 'string') {
        return '';
    }
    if (typeof charLimit !== 'number' || text.length <= charLimit) {
        return text;
    }
    charLimit = Math.max(charLimit - 3, 0);
    return `${text.slice(0, charLimit)}...`;
}

class StorefrontAboutSection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            charLimit: ABOUT_CHAR_LIMIT,
            showAll: false,
            isContactModalOpen: false,
        };

        this._updateAboutCharLimit = this._updateAboutCharLimit.bind(this);
        this._updateHeight = this._updateHeight.bind(this);
        this._onClickReadMore = this._onClickReadMore.bind(this);
        this._onClickContactInfo = this._onClickContactInfo.bind(this);
    }

    componentDidMount() {
        this._updateAboutCharLimit();
        window.addEventListener('resize', this._updateAboutCharLimit);
        if (this.props.seller?.storefrontProfile?.aboutUs) {
            window.addEventListener('resize', this._updateHeight);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._updateHeight);
        window.removeEventListener('resize', this._updateAboutCharLimit);
    }

    /**
     * Calculates and updates the number of about us text characters that should show
     */
    _updateAboutCharLimit() {
        const winW = window.innerWidth;
        if (!winW) {
            return;
        }

        const newCharLimit = winW < 567 ? ABOUT_CHAR_LIMIT_MOBILE : ABOUT_CHAR_LIMIT;
        if (newCharLimit !== this.state.charLimit) {
            this.setState({ charLimit: newCharLimit });
        }
    }

    _updateHeight() {
        const sectionEl = findDOMNode(this);
        const aboutEl = sectionEl.getElementsByClassName(styles.about)[0];
        const aboutUsEl = sectionEl.getElementsByClassName(styles.aboutUs)[0];

        if (aboutEl && aboutUsEl) {
            // Adjust height to smooth transition
            aboutEl.style.height = aboutUsEl.offsetHeight + 'px';
        }
    }

    _onClickReadMore(e) {
        e.preventDefault();
        this.setState(state => ({ showAll: !state.showAll }), this._updateHeight);
    }

    _onClickContactInfo(e) {
        e.preventDefault();
        this.setState({ isContactModalOpen: true });
    }

    renderDealerAbout() {
        const charLimit = this.state.charLimit;

        let link = (
            <FormattedMessage id="storefront.about.section.readLess" defaultMessage="Read Less" />
        );
        let aboutUs = this.props.seller?.storefrontProfile?.aboutUs;

        if (aboutUs.length < charLimit) {
            link = null;
        } else if (!this.state.showAll) {
            link = (
                <FormattedMessage
                    id="storefront.about.section.readMore"
                    defaultMessage="Read More"
                />
            );
            aboutUs = addEllipsis(aboutUs, charLimit);
        }

        return (
            <div className={styles.about}>
                <p className={styles.aboutUs}>
                    {aboutUs}
                    <span
                        onClick={this._onClickReadMore}
                        onKeyPress={this._onClickReadMore}
                        role="button"
                        tabIndex={-1}
                        className={styles.readMoreLess}
                    >
                        {link}
                    </span>
                </p>
            </div>
        );
    }

    renderDealerInfo() {
        const { sellerProfile, storefrontProfile, tradeAssociations, sellerPreferences, vatInfo } =
            this.props?.seller || {};
        const sellerLogo = storefrontProfile?.logo?.path;
        const scrollLogo = sellerPreferences?.scrollLogoLg;
        const logo = sellerLogo || scrollLogo; // v2 versus v1 logos
        const company = sellerProfile?.company;
        const establishmentYear = storefrontProfile?.establishmentYear;

        // ORDERET-457 do not display EU VAT info
        const nonEUVatInfo = vatInfo?.filter(v => !!v.priceBookName && v.priceBookName !== 'EU');
        const showVatInfo = !!nonEUVatInfo?.length && nonEUVatInfo.some(v => v.vatId);

        return (
            <div className={styles.info}>
                {/* Dealer Logo */}
                {logo ? (
                    <img
                        data-tn="storefront-about-section-image"
                        className={styles.logo}
                        src={logo}
                        alt={company}
                    />
                ) : (
                    <p className={styles.logo}>{company}</p>
                )}

                <p>
                    {/* Established in {YEAR} */}
                    {establishmentYear && (
                        <span
                            data-tn="storefront-about-section-establishmentYear"
                            className={styles.yearJoined}
                        >
                            <FormattedMessage
                                id="StorefrontAboutSection.establishmentYear"
                                defaultMessage="Established in {establishmentYear}"
                                values={{ establishmentYear }}
                            />
                            <span className={styles.divider} />
                        </span>
                    )}
                    {/* Joined 1stDibs {YEAR} */}
                    <span
                        data-tn="storefront-about-section-dealer-since"
                        className={styles.yearJoined}
                    >
                        <FormattedMessage
                            id="StorefrontAboutSection.yearJoined"
                            defaultMessage="1stDibs seller since {year}"
                            values={{ year: this.props.sellerData?.dealerSince }}
                        />
                    </span>
                </p>

                {/* Associations */}
                {tradeAssociations?.length ? (
                    <div>
                        <p
                            data-tn="storefront-about-section-associations"
                            className={styles.associations}
                        >
                            <FormattedMessage
                                id="StorefrontAboutSection.associations"
                                defaultMessage="Associations"
                            />
                        </p>

                        {tradeAssociations.map((association, index) => {
                            const key = `${association?.displayName}${index}`;

                            return (
                                <p key={key} className={styles.association}>
                                    {association?.displayName}
                                </p>
                            );
                        })}
                    </div>
                ) : null}

                {showVatInfo && (
                    <>
                        <p className={styles.vatInfoLabel}>
                            <FormattedMessage
                                id="StorefrontAboutSection.vatInformation"
                                defaultMessage="VAT ID"
                            />
                        </p>
                        <p className={styles.vatInfo}>
                            {nonEUVatInfo.map(({ vatId }, index) => {
                                if (vatId) {
                                    return (
                                        <FormattedMessage
                                            key={index}
                                            id="storefront.vatNumber"
                                            defaultMessage="{hasSeparator, select, true {{vatId}, } other {{vatId}}}"
                                            values={{
                                                vatId,
                                                hasSeparator: index < nonEUVatInfo.length - 1,
                                            }}
                                        />
                                    );
                                }
                                return null;
                            })}
                        </p>
                    </>
                )}

                {shouldRenderContactModal(this.props.seller) ? (
                    <Link
                        dataTn="storefront-about-section-contact"
                        className={styles.contact}
                        onClick={this._onClickContactInfo}
                    >
                        <FormattedMessage
                            id="StorefrontAboutSection.contactCta"
                            defaultMessage="Contact Info"
                        />
                    </Link>
                ) : null}
            </div>
        );
    }

    render() {
        // minimal layout is for dealers who don't have an aboutUs text
        const seller = this.props.seller;
        const minLayout = !seller?.storefrontProfile?.aboutUs;
        const className = classNames(styles.section, {
            [styles.minimal]: minLayout,
        });

        return (
            <section className={className}>
                {/* Header (About {Dealer Name} */}
                {!minLayout ? (
                    <StorefrontSectionHeader
                        header={
                            <FormattedMessage
                                id="StorefrontAboutSection.header"
                                defaultMessage="About {company}"
                                values={{
                                    company: seller?.sellerProfile?.company,
                                }}
                            />
                        }
                    />
                ) : null}

                {/* Content */}
                <div className={styles.content}>
                    {!minLayout ? (
                        <div className="rowFlex">
                            {/* Left */}
                            <div className={`colLg9 colMd8 colXs12`}>
                                {this.renderDealerAbout()}
                            </div>

                            {/* Right */}
                            <div className={`colLg3 colMd4 colXs12`}>{this.renderDealerInfo()}</div>
                        </div>
                    ) : (
                        this.renderDealerInfo()
                    )}
                </div>

                <StorefrontContactModal
                    seller={seller}
                    isOpen={this.state.isContactModalOpen}
                    closeModal={() => this.setState({ isContactModalOpen: false })}
                />
            </section>
        );
    }
}

StorefrontAboutSection.propTypes = {
    sellerData: PropTypes.shape({
        dealerSince: PropTypes.string.isRequired,
    }).isRequired,
    seller: PropTypes.object.isRequired,
};

export default createFragmentContainer(StorefrontAboutSection, {
    seller: graphql`
        fragment StorefrontAboutSection_seller on Seller {
            ...StorefrontContactModal_seller
            storefrontProfile(storefrontId: $storefrontId) {
                aboutUs
                logo {
                    path
                }
                establishmentYear
            }
            sellerProfile {
                company
            }
            sellerPreferences {
                scrollLogoLg
            }
            tradeAssociations {
                displayName
            }
            vatInfo @include(if: $showVatInfo) {
                vatId
                priceBookName
            }
        }
    `,
});
