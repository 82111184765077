import { type SyntheticEvent, type FunctionComponent } from 'react';
import Back from 'dibs-icons/exports/legacy/ArrowLeft';
import classnames from 'classnames';
import { defineMessages, useIntl } from 'dibs-react-intl';

import styles from './main.scss';

type ModalBackButtonProps = {
    onClick: (e: SyntheticEvent<HTMLElement>) => void;
    className?: string;
    disabled?: boolean;
    backgroundColor?: string;
};
const messages = defineMessages({
    label: {
        id: 'modal.ModalBackButton.label',
        defaultMessage: 'Back',
    },
});
export const ModalBackButton: FunctionComponent<ModalBackButtonProps> = props => {
    const { className, onClick, disabled, backgroundColor = 'transparent' } = props;
    const intl = useIntl();

    let bgColorClass = backgroundColor;
    if (backgroundColor === 'translucentWhite') {
        bgColorClass = styles.translucentWhite;
    }

    const wrapperClass = classnames(styles.backButton, className, {
        [styles.backButtonDisabled]: disabled,
        [bgColorClass]: !!backgroundColor,
    });

    return (
        <button
            aria-label={intl.formatMessage(messages.label)}
            className={wrapperClass}
            onClick={disabled ? () => {} : onClick}
        >
            <Back />
        </button>
    );
};
