import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type FacebookProps = {
    className?: string;
    role?: 'img';
    ariaLabel?: string;
    ariaLabelledby?: string;
};

const Facebook: FunctionComponent<FacebookProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'facebook'}
            role={props.role}
            aria-label={props.ariaLabel}
            aria-labelledby={props.ariaLabelledby}
            viewBox="0 0 250 250"
        >
            <path d="M141.7 250V135.8H180l5.7-44.3h-44.2V63.2c0-13 3.6-21.5 21.9-21.5H187v-40c-4-.6-17.9-1.7-34.2-1.7-34 0-57.2 20.8-57.2 58.7v32.8h-38v44.3h38.3V250h45.8z" />
        </svg>
    );
};
export default Facebook;
