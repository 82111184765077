import { type FC, lazy, Suspense, useEffect, useRef } from 'react';
import { useQueryLoader } from 'react-relay';
import { Query, initialVars } from './SellerReviewsModalQuery';

import { type SellerReviewsModalQuery } from './__generated__/SellerReviewsModalQuery.graphql';

const SellerReviewsModal = lazy(
    () => import(/* webpackChunkName: "SellerReviewsModal" */ './SellerReviewsModal')
);

export const SellerReviewsModalAsync: FC<{
    onClose: () => void;
    isOpen: boolean;
    sellerId: string | null;
    isMobileScrollContainer?: boolean;
}> = ({ isMobileScrollContainer = false, onClose, isOpen, sellerId }) => {
    const [queryReference, loadQuery] = useQueryLoader<SellerReviewsModalQuery>(Query);
    const executed = useRef(false);

    useEffect(() => {
        if (isOpen && !executed.current && sellerId) {
            loadQuery({
                ...initialVars,
                sellerId,
                isMobileScrollContainer,
            });
            executed.current = true;
        } else if (!isOpen && executed.current) {
            //if user closes modal while fetching dispose queryReference
            queryReference?.dispose();
            executed.current = false;
        }
    }, [isOpen, loadQuery, sellerId, isMobileScrollContainer, queryReference]);

    if (!isOpen || !sellerId) {
        return null;
    }

    return (
        <Suspense fallback={null}>
            {queryReference ? (
                <SellerReviewsModal
                    onClose={onClose}
                    isOpen={isOpen}
                    sellerId={sellerId}
                    queryReference={queryReference}
                    isMobileScrollContainer={isMobileScrollContainer}
                />
            ) : null}
        </Suspense>
    );
};
