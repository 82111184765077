import { graphql } from 'react-relay';
import {
    ORDER_BY_NEWEST,
    ORDER_BY_HIGHEST,
    ORDER_BY_LOWEST,
    type OrderBy as OrderByType,
} from './constants';

import { type SellerReviewsModalQuery$variables } from './__generated__/SellerReviewsModalQuery.graphql';

export type OrderBy = OrderByType;

export const DEFAULT_SORT = ORDER_BY_NEWEST;

export const getSortOrders = (
    sortVal: string
): { [ORDER_BY_NEWEST]: boolean; [ORDER_BY_HIGHEST]: boolean; [ORDER_BY_LOWEST]: boolean } => ({
    [ORDER_BY_NEWEST]: sortVal === ORDER_BY_NEWEST,
    [ORDER_BY_HIGHEST]: sortVal === ORDER_BY_HIGHEST,
    [ORDER_BY_LOWEST]: sortVal === ORDER_BY_LOWEST,
});

export const initialVars: SellerReviewsModalQuery$variables = {
    sellerId: '',
    isMobileScrollContainer: false,
    ...getSortOrders(DEFAULT_SORT),
    page: 1,
};

export const Query = graphql`
    query SellerReviewsModalQuery(
        $sellerId: String!
        $isMobileScrollContainer: Boolean!
        $orderByNewest: Boolean!
        $orderByHighest: Boolean!
        $orderByLowest: Boolean!
        $page: Int!
    ) {
        viewer {
            ...SellerReviewsModalContent_viewer @skip(if: $isMobileScrollContainer)
            ...SellerReviewsMobileModalContent_viewer @include(if: $isMobileScrollContainer)
        }
    }
`;
