/**
 * @generated SignedSource<<1ddf3c0600817277a13d9fb8cae5c982>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontCallButton_seller$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CallDealerModal_seller">;
  readonly " $fragmentType": "StorefrontCallButton_seller";
};
export type StorefrontCallButton_seller$key = {
  readonly " $data"?: StorefrontCallButton_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontCallButton_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontCallButton_seller",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CallDealerModal_seller"
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "a8af87f24ce4fedc6301199de7bc190d";

export default node;
