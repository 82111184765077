import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type PinterestProps = {
    className?: string;
};

const Pinterest: FunctionComponent<PinterestProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'pinterest'}
            viewBox="0 0 250 250"
        >
            <path d="M130.7 2C63.6 2 29.9 50 29.9 90c0 24.3 9.2 45.8 28.9 53.7 3.3 1.3 6.2 0 7.1-3.5.8-2.5 2.1-8.9 2.9-11.4 1-3.5.8-4.8-2.1-7.7-5.6-6.7-9.4-15.4-9.4-27.7 0-35.6 26.6-67.6 69.5-67.6 37.9 0 58.7 23.1 58.7 53.9 0 40.8-17.9 74.9-44.9 74.9-14.6 0-25.8-12.1-22.1-27 4.2-17.9 12.5-37 12.5-50.1 0-11.7-6.2-21.2-19.1-21.2-15 0-27.2 15.8-27.2 36.6 0 13.3 4.6 22.3 4.6 22.3S73.7 180.7 71.2 192c-5.2 22.7-.8 50.7-.6 53.5.2 1.7 2.3 2.1 3.3.8 1.3-1.9 19.5-24.1 25.6-46.2 1.7-6.2 9.8-38.9 9.8-38.9 4.8 9.4 19.3 17.5 34.5 17.5 45.3 0 76.1-41.2 76.1-96.7.1-41.3-35.3-80-89.2-80z" />
        </svg>
    );
};
export default Pinterest;
