import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type NumberInputDisplayProps = {
    className?: string;
};

const NumberInputDisplay: FunctionComponent<NumberInputDisplayProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'number-input-display'}
            viewBox="0 0 250 250"
        >
            <path
                fill="#FFFFFF"
                d="M243.7 87.6h-47.4v4.5h-40.8v-4.5H6.3c-1.1 0-2 .9-2 2v68.7c0 1.1.9 2 2 2h149.3v-4.5h40.8v4.5h47.4c1.1 0 2-.9 2-2V89.6c-.1-1.1-1-2-2.1-2z"
            />
            <path d="M155.5 155.8H8.8V92.1h146.8v-9H6.3c-3.6 0-6.5 2.9-6.5 6.5v68.7c0 3.6 2.9 6.5 6.5 6.5h149.3v-9z" />
            <path d="M243.7 83.1h-47.4v9h44.9v63.7h-44.9v9h47.4c3.6 0 6.5-2.9 6.5-6.5V89.6c0-3.6-2.9-6.5-6.5-6.5z" />
            <path d="M194.8 188h-14V60.4h14c2.5 0 4.5-2 4.5-4.5s-2-4.5-4.5-4.5h-14.2c-2.4 0-4.3 1.9-4.5 4.2-.1-2.4-2-4.4-4.5-4.4h-14.2c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5h14.4v127.6h-14.4c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5h14.2c2.4 0 4.3-1.9 4.5-4.2.1 2.4 2 4.4 4.5 4.4h14.2c2.5 0 4.5-2 4.5-4.5s-2-4.5-4.5-4.5z" />
            <path d="M30.9 143.6l2-9.6h-4.7v-6.6h5.9l1.5-7h-7.4v-6.6h8.7l2.1-9.6h6.8l-2 9.6h6.6l1.9-9.6h6.9l-2 9.6h5.2v6.6h-6.5l-1.3 7h7.8v6.6h-9.2l-2 9.6h-6.9l2-9.6h-6.6l-1.9 9.6h-6.9zm10.2-16.2h6.5l1.4-7h-6.6l-1.3 7z" />
            <path d="M73.4 143.6l2-9.6h-4.7v-6.6h5.9l1.5-7h-7.4v-6.6h8.7l2.1-9.6h6.8l-2 9.6h6.6l1.9-9.6h6.9l-2 9.6h5.2v6.6h-6.5l-1.3 7h7.8v6.6h-9.2l-2 9.6h-6.9l2-9.6h-6.6l-1.9 9.6h-6.9zm10.2-16.2h6.5l1.4-7h-6.6l-1.3 7z" />
            <path d="M115.9 143.6l2-9.6h-4.7v-6.6h5.9l1.5-7h-7.4v-6.6h8.7l2.1-9.6h6.8l-2 9.6h6.6l1.9-9.6h6.9l-2 9.6h5.2v6.6h-6.5l-1.3 7h7.8v6.6h-9.2l-2 9.6h-6.9l2-9.6h-6.6l-1.9 9.6h-6.9zm10.2-16.2h6.5l1.4-7h-6.6l-1.3 7z" />
        </svg>
    );
};
export default NumberInputDisplay;
