import { Component } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import * as tracking from 'dibs-tracking';
import { CurrencyProvider } from 'dibs-buyer-layout/exports/CurrencyProvider';
import { QuickViewProvider } from 'dibs-search-product-tile/exports/QuickViewProvider';

import StorefrontProductTile from './StorefrontProductTile';

const {
    trackingConstants: { ECOM_PRODUCT_IMPRESSION },
    eventNameConstants: { EVENT_VIEW_ITEM_LIST },
} = tracking;

class StorefrontProductGrid extends Component {
    componentDidMount() {
        const edges = this.props.products?.edges || [];
        const products = edges.map(({ node = {} }, index) => ({
            ...node.ecommerceTrackingParams,
            categoryCode: node.categoryCode,
            category: node.categoryPath,
            position: index + 1,
            list: 'storefront featured listing',
        }));

        tracking.trackEcommerce({
            type: ECOM_PRODUCT_IMPRESSION,
            eventName: EVENT_VIEW_ITEM_LIST,
            products,
        });
    }

    render() {
        const { user, viewer, isTrade } = this.props;

        const { gridClass, gridItemClass } = this.props.classes;
        const userId = user && user.serviceId;
        const userIds = user ? [user.serviceId] : [];
        const productGridEdges = this.props.products?.edges || [];

        return (
            <CurrencyProvider
                render={({ currency, showCurrency } = {}) => {
                    return (
                        <QuickViewProvider>
                            <div data-tn="storefront-home-product-grid" className={gridClass}>
                                {productGridEdges.map((product, index) => (
                                    <div
                                        data-tn="storefront-home-product-tile"
                                        className={gridItemClass}
                                        key={product.node.serviceId}
                                    >
                                        <StorefrontProductTile
                                            index={index}
                                            product={product.node}
                                            currency={currency}
                                            showCurrency={showCurrency}
                                            isTrade={isTrade}
                                            userId={userId}
                                            userIds={userIds}
                                            user={user || null}
                                            viewer={viewer}
                                        />
                                    </div>
                                ))}
                            </div>
                        </QuickViewProvider>
                    );
                }}
            />
        );
    }
}

StorefrontProductGrid.propTypes = {
    classes: PropTypes.shape({
        gridClass: PropTypes.string.isRequired,
        gridItemClass: PropTypes.string.isRequired,
    }).isRequired,

    isTrade: PropTypes.bool.isRequired,

    // relay fragments
    user: PropTypes.object,
    viewer: PropTypes.object.isRequired,
    products: PropTypes.object.isRequired,

    relay: PropTypes.object.isRequired,
};

export default createFragmentContainer(StorefrontProductGrid, {
    viewer: graphql`
        fragment StorefrontProductGrid_viewer on Viewer {
            ...StorefrontProductTile_viewer
        }
    `,
    user: graphql`
        fragment StorefrontProductGrid_user on User {
            serviceId
            ...StorefrontProductTile_user
        }
    `,
    products: graphql`
        fragment StorefrontProductGrid_products on DealerStorefrontQueryConnection {
            edges {
                node {
                    serviceId
                    categoryPath
                    categoryCode
                    ecommerceTrackingParams
                    ...StorefrontProductTile_product
                }
            }
        }
    `,
});
