/**
 * @generated SignedSource<<7d4382e637092adae6ebc254b12ee2ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontProductGrid_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontProductTile_viewer">;
  readonly " $fragmentType": "StorefrontProductGrid_viewer";
};
export type StorefrontProductGrid_viewer$key = {
  readonly " $data"?: StorefrontProductGrid_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontProductGrid_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontProductGrid_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontProductTile_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "f1cf5bc5923dd762b08b8db854ff6678";

export default node;
