/**
 * @generated SignedSource<<06921a5f52f62d8cae2be6d4d2c41341>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontHead_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontSocial_viewer">;
  readonly " $fragmentType": "StorefrontHead_viewer";
};
export type StorefrontHead_viewer$key = {
  readonly " $data"?: StorefrontHead_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontHead_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontHead_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontSocial_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "2d05f21453267818c21dab5d8922a2a2";

export default node;
