/**
 * @generated SignedSource<<899e200d9d0ee84bdea5c450f4884240>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontHome_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontFeaturedSection_user">;
  readonly " $fragmentType": "StorefrontHome_user";
};
export type StorefrontHome_user$key = {
  readonly " $data"?: StorefrontHome_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontHome_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontHome_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontFeaturedSection_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "c8f3973dfb191c16a7a06932ffba73d2";

export default node;
