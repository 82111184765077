/**
 * @generated SignedSource<<8860d211143afd658d3bc2aa7b3e8965>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontProductGrid_user$data = {
  readonly serviceId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontProductTile_user">;
  readonly " $fragmentType": "StorefrontProductGrid_user";
};
export type StorefrontProductGrid_user$key = {
  readonly " $data"?: StorefrontProductGrid_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontProductGrid_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontProductGrid_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontProductTile_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "afdf0ee6cbdf3256b81521631216ee4f";

export default node;
