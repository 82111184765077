import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type PhoneRingingProps = {
    className?: string;
};

const PhoneRinging: FunctionComponent<PhoneRingingProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'phone-ringing'}
            viewBox="0 0 250 250"
        >
            <path
                fill="#FFFFFF"
                d="M166.6 245.5h-85c-1.1 0-2-.9-2-2V70.8c0-1.1.9-2 2-2h85.1c1.1 0 2 .9 2 2v172.7c-.1 1.1-1 2-2.1 2z"
                className="phone"
            />
            <path
                d="M166.6 250h-85c-3.6 0-6.5-2.9-6.5-6.5V70.8c0-3.6 2.9-6.5 6.5-6.5h85.1c3.6 0 6.5 2.9 6.5 6.5v172.7c-.1 3.6-3 6.5-6.6 6.5zm-82.5-9h80.1V73.3H84.1V241z"
                className="phone"
            />
            <path
                d="M141.6 84.5h-35.1c-.2 0-.3-.2-.3-.4l-1.9-11.2c0-.2.1-.4.3-.4h38.9c.2 0 .3.2.3.4l-1.9 11.2c0 .2-.2.4-.3.4z"
                className="phone"
            />
            <path
                d="M79.4 45.3c-1.2 0-2.3-.4-3.2-1.3L53.9 21.7c-1.8-1.8-1.8-4.6 0-6.4 1.8-1.8 4.6-1.8 6.4 0l22.3 22.3c1.8 1.8 1.8 4.6 0 6.4-.9.9-2.1 1.3-3.2 1.3z"
                className="ray1"
            />
            <path
                d="M123 41c-2.5 0-4.5-2-4.5-4.5V5c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5v31.5c0 2.5-2.1 4.5-4.5 4.5z"
                className="ray2"
            />
            <path
                d="M165.1 45.3c-1.2 0-2.3-.4-3.2-1.3-1.8-1.8-1.8-4.6 0-6.4l22.3-22.3c1.8-1.8 4.6-1.8 6.4 0 1.8 1.8 1.8 4.6 0 6.4L168.3 44c-.9.9-2.1 1.3-3.2 1.3z"
                className="ray3"
            />
            <path
                d="M227.5 70H196c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5h31.5c2.5 0 4.5 2 4.5 4.5s-2 4.5-4.5 4.5z"
                className="ray4"
            />
            <path
                d="M51.9 70H20.4c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5h31.5c2.5 0 4.5 2 4.5 4.5s-2 4.5-4.5 4.5z"
                className="ray5"
            />
        </svg>
    );
};
export default PhoneRinging;
