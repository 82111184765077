import { type FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';

import { handleLocaleUrl, GLOBAL_CLIENT_ONLY_LOCALE } from 'dibs-intl/exports/urls';
import { Button } from 'dibs-elements/exports/Button';

import styles from './styles.scss';

type Props = {
    conversationLinkHref?: string;
};

const CallInitiatedSuccess: FC<Props> = ({ conversationLinkHref = '/inbox/' }) => {
    return (
        <div className={styles.prompt}>
            <FormattedMessage
                id="dc.buyerInitiateCall.messageSeller"
                defaultMessage="If the seller doesn’t answer, you can leave a message after the tone or message the seller to schedule another time to call."
            />
            <Button
                className={styles.button}
                dataTn="go-to-conversation-button"
                onClick={() => {
                    window.location.assign(
                        handleLocaleUrl(conversationLinkHref, GLOBAL_CLIENT_ONLY_LOCALE)
                    );
                }}
                fullWidth
            >
                <FormattedMessage
                    id="dc.buyerInitiateCall.goToConversation"
                    defaultMessage="Go to Conversation"
                />
            </Button>
        </div>
    );
};

export default CallInitiatedSuccess;
